import styled, { css } from 'styled-components';
import { SButtonInput } from 'components/Toolkit/InputButton/InputButton';
import { SButtonDropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { media } from 'helpers/breakpoints';

export const List = styled.ul`
  ${({ theme }) => theme.fontSize.M14};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  padding: 0 0 1px 0;
`;

export const SButtonInputDisabled = css`
  ${SButtonInput},
  ${SButtonDropdown} {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
    color: ${({ theme }) => theme.colors.GREY_LIGHT};
    border-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

export const Mobile = styled.div<{ isDisabled?: boolean }>`
  display: block;
  ${({ isDisabled }) => isDisabled && SButtonInputDisabled};
  ${media.large} {
    display: none;
  }
`;

export const Desktop = styled.div<{ isDisabled?: boolean }>`
  display: none;
  ${({ isDisabled }) => isDisabled && SButtonInputDisabled};
  ${media.large} {
    display: block;
  }
`;

export const ListItem = styled.div<{ isDefault?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  width: 100%;
  height: 40px;
  text-align: left;
  margin: ${({ isDefault }) => (isDefault ? `0 0 1px` : `1px 0`)};
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.OFFWHITE};
  }
`;

export const ListWrapper = styled.div``;

export const ListHeadingItem = styled.span`
  ${({ theme }) => theme.fontSize.S12};
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  margin: -1px 0;
`;
