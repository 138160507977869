import styled from 'styled-components';
import type { ChangeEvent } from 'react';
import type { StyledComponent, DefaultTheme } from 'styled-components';

export interface IRadioTab {
  name: string;
  value: string;
  displayValue: string;
  checked?: boolean;
  hasError?: boolean;
  required?: boolean;
  onChange?: (t: string) => void;
}

type TTabLabel = {
  checked?: boolean;
  hasError?: boolean;
};
const TabLabel: StyledComponent<
  'label',
  DefaultTheme,
  TTabLabel,
  never
> = styled.label<TTabLabel>`
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.GREY_DARK};
  ${({ theme }) => theme.fontSize.M16}
  cursor: pointer;
  box-sizing: border-box;

  ${({ theme, hasError }) => {
    // Shared styles (state specific)
    switch (hasError) {
      case true:
        return `
          border-left: 1px solid ${theme.colors.RED_DARK};
          color: ${theme.colors.RED_DARK};`;
      default:
        return `
          border-left: 1px solid ${theme.colors.GREY};`;
    }
  }};

  &:first-child {
    border-left: 0;
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.default} 0 0 ${theme.borderRadius.default}`};
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    border-radius: ${({ theme }) =>
      `0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0`};
  }

  /* Shared checked styles  */
  ${({ theme, checked }) =>
    checked &&
    `
      color: ${theme.colors.GREY_DARKER};
      font-weight: ${theme.fontWeight.bold};
  `}

  /* Checked styles (tab specific) */
   ${({ theme, hasError }) => hasError && `color: ${theme.colors.RED_DARK}`};
`;

const TabInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  /* Required for safari (remove radio while keeping focus outline) */
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:focus {
    opacity: 1;
    outline-color: ${({ theme }) => theme.colors.BLUE};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

function RadioTab({
  name,
  value,
  displayValue,
  checked = false,
  hasError,
  onChange,
  required = false,
}: IRadioTab) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(event.target.value);
  }

  return (
    <TabLabel
      htmlFor={value}
      checked={checked}
      aria-checked={checked}
      hasError={hasError}
    >
      <TabInput
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={checked}
        onChange={handleChange}
        required={required}
      />
      {displayValue}
    </TabLabel>
  );
}

export { RadioTab };
