import styled from 'styled-components';
import { Fragment, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { RadioTab } from 'components/Toolkit/Inputs/RadioTab';
import type { IRadioTab } from 'components/Toolkit/Inputs/RadioTab';
import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { SubText } from 'components/Toolkit/Inputs/SubText';
import { Label } from 'components/Toolkit/Inputs/Label';

type Option = {
  value: string;
  displayValue: string;
};

export interface IRadioTabs {
  name: string;
  value?: string;
  tabLabel?: string;
  options: Option[];
  required?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  infoMessage?: string | null;
  willUseSubText?: boolean;
  topRightNode?: ReactNode;
  FinalFormRadioTab?: (
    t: IRadioTab & { initialiseAsSelected: () => void },
  ) => JSX.Element;
  className?: string;
  onChange?: (t: string) => void;
}

type TTabWrapper = {
  hasError: boolean;
};
const TabWrapper = styled.div<TTabWrapper>`
  position: relative;
  height: 40px;
  display: flex;
  align-items: stretch;
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: ${({ theme, hasError }) =>
    `1px solid ${hasError ? theme.colors.RED_DARK : theme.colors.GREY}`};
  margin-bottom: ${({ theme }) => theme.spacing.S4};
`;

const LabelAndNodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type TSelected = { index: number; amount: number; hasError?: boolean };
const Selected = styled.div<TSelected>`
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: ${({ index, amount }) => `calc(-1px + ${index}/${amount} * 100%)`};
  width: ${({ amount }) => `calc(1/${amount} * 100% + 2px)`};
  border: ${({ theme, hasError }) =>
    `2px solid ${hasError ? theme.colors.RED_DARK : theme.colors.BLACK}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: left 200ms ease-in-out;
`;

function RadioTabs({
  name,
  value,
  tabLabel,
  options,
  required = false,
  hasError = false,
  errorMessage,
  infoMessage,
  willUseSubText = true,
  topRightNode,
  FinalFormRadioTab,
  className,
  onChange,
}: IRadioTabs) {
  const optionsAmount = options.length;
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);
  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue,
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  function handleChange(value: string) {
    setSelectedValue(value);
    onChange && onChange(value);
  }

  return (
    <div className={className}>
      <LabelAndNodeContainer>
        {tabLabel && <Label>{tabLabel}</Label>}
        {topRightNode}
      </LabelAndNodeContainer>
      <TabWrapper
        id={name}
        role="radiogroup"
        aria-labelledby={name}
        hasError={hasError}
      >
        {options.map(({ value, displayValue }, index) => (
          <Fragment key={index}>
            {FinalFormRadioTab ? (
              <FinalFormRadioTab
                name={name}
                value={value}
                displayValue={displayValue}
                hasError={hasError}
                onChange={(t) => handleChange(t)}
                required={required}
                initialiseAsSelected={() => setSelectedValue(value)}
              />
            ) : (
              <RadioTab
                name={name}
                value={value}
                displayValue={displayValue}
                checked={selectedValue === value}
                hasError={hasError}
                onChange={(t) => handleChange(t)}
                required={required}
              />
            )}
          </Fragment>
        ))}
        {selectedValue !== undefined && (
          <Selected
            index={selectedIndex}
            amount={optionsAmount}
            hasError={hasError}
          />
        )}
      </TabWrapper>
      {willUseSubText && (
        <SubText>
          {errorMessage && <ErrorMessage text={errorMessage} />}
          {infoMessage && <HelpText text={infoMessage} />}
        </SubText>
      )}
    </div>
  );
}

export { RadioTabs };
